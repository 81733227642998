import 'core-js/actual';
import { Script } from 'gatsby';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';

import './src/libs/i18n';
import './src/libs/monitoring';
import './src/css/custom.scss';

export const shouldUpdateScroll = () => {
	return false;
};

export const wrapRootElement = ({ element }) => {
	return (
		<>
			<HelmetProvider>
				<CookiesProvider>{element}</CookiesProvider>
			</HelmetProvider>
			<Script
				data-domain="app.zample.com"
				src="https://plausible.io/js/script.outbound-links.pageview-props.tagged-events.js"
			/>
		</>
	);
};

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translation_en from '../locales/en/translations.json';
import translation_sv from '../locales/sv/translations.json';

const languageDetector = new LanguageDetector();

i18n
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		detection: { order: ['localStorage'] },
		fallbackLng: 'sv',
		interpolation: {
			escapeValue: false,
		},
		keySeparator: true,
		ns: 'translations',
		resources: {
			en: {
				translations: translation_en,
			},
			sv: {
				translations: translation_sv,
			},
		},
		supportedLngs: ['en', 'sv'],
	});

export default i18n;
